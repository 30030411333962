.banner-wrapper {
  background-color: black;
  min-height: 100vh;
  padding-bottom: 3rem;

  .header-nav {
    .download-btn {
      background-color: black;
      border: 1px solid #67df83;
      border-radius: 100px;
      color: #67df83;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      min-width: 166px;
      transition: ease 0.3ms;

      &:hover {
        background-color: #67df83;
        color: black;
      }
    }
  }

  .choose-category {
    background-color: #67df83;
    border: 1px solid #67df83;
    border-radius: 100px;
    color: black;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    min-width: 166px;
    transition: ease 0.3ms;
    font-weight: 600;
  }

  .select-option {
    color: #ffffff99;
  }
}

.banner-img {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: end;
  text-align: right;
  justify-content: flex-end;

  .frame-section {
    position: relative;
    left: 0px;
    right: 0px;
    width: max-content;
  }
}

.banner-text-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Bitter", serif;

  h3 {
    font-size: 56px;
    line-height: 1.5;
    color: white;
    font-weight: 800;
  }

  p {
    color: #ffffff99;
    font-family: "Quicksand", sans-serif;
    font-size: 20px;
  }
}

/* Container setup */
.carousel-container {
  display: flex;
  overflow: hidden;
  width: 100%;

  .Marquee {
    background-color: #67df83;
  }
}

/* Scrolling images */
.carousel-track {
  display: flex;
  animation: scroll 20s linear infinite;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Images */
.carousel-track img {
  width: 200px;
  /* Set image width */
  height: auto;
  padding: 10px;
}

/* Keyframes for smooth scrolling effect */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

/* Duplicate track for smooth looping */
.carousel-track:after {
  content: "";
  display: flex;
}

.how-it-works-wrapper {
  background-color: #ededf1;
  padding: 85px 0px;

  .header-line {
    text-align: center;

    h3 {
      font-size: 2rem;
      line-height: 1.2;
      font-family: "Bitter", serif;
      font-weight: 800;
      margin-bottom: 0px;
      color: #080808;

      @media (max-width: 575px) {
        font-size: 1rem;
      }
    }

    p {
      font-size: 20px;
      line-height: 1.5;
      color: #08080899;
    }
  }
}

.first-section {
  background-color: #f9f9f9;
  border-radius: 24px;
  padding: 1rem;
  display: flex;
  flex-direction: row;

  h3 {
    font-size: 2.5rem;
    color: #080808;
  }

  p {
    font-size: 20px;
    line-height: 1.5;
    color: #08080899;
  }
}

#first-section {
  flex-direction: row-reverse;

  @media (max-width: 1199px) {
    flex-direction: column;
  }

  @media (max-width: 990px) {
    flex-direction: column;
  }

  @media (max-width: 575px) {
    flex-direction: column;
  }
}

.reward-wrap {
  background-color: #080808;
  padding: 60px;
  display: flex;
  align-items: center;

  .header-line {
    text-align: center;

    h3 {
      font-size: 56px;
      line-height: 1.2;
      font-family: "Bitter", serif;
      font-weight: 800;
      margin-bottom: 10px;
      color: #080808;
    }

    p {
      font-size: 20px;
      line-height: 1.5;
      color: #ffffff99;
    }
  }

  .img-wrap {
    display: flex;
    gap: 10px;
    overflow: hidden;
    width: 100%;
    align-items: center;
    justify-content: center;

    .box {
      width: 33%;

      img {
        width: 100%;
      }
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.why-customer-us {
  padding: 5rem 0px;
  background-color: white;

  .header-line {
    text-align: center;

    h3 {
      font-size: 56px;
      line-height: 1.2;
      font-family: "Bitter", serif;
      font-weight: 800;
      margin-bottom: 10px;
      color: #080808;
    }

    p {
      font-size: 20px;
      line-height: 1.5;
      color: #7f7f7f;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    width: 100%;
    margin: auto;
  }

  .box {
    background-color: #f0f0f0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
    font-size: 20px;
    flex-direction: column;
    border-radius: 24px;

    h3 {
      color: #080808;
      font-size: 40px;
      font-weight: 800;
    }

    p {
      color: #08080899;
      font-size: 1rem;
    }
  }
}

.why-choose-wrap {
  padding: 5rem 0px;
  background-color: #000000;

  .header-line {
    text-align: center;

    h3 {
      font-size: 56px;
      line-height: 1.2;
      font-family: "Bitter", serif;
      font-weight: 800;
      margin-bottom: 10px;
      color: white;
    }

    p {
      font-size: 20px;
      line-height: 1.5;
      color: #7f7f7f;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    max-width: 610px;
    padding: 1.5rem;
    background-color: transparent;
    border-radius: 10px;

    label {
      font-size: 24px;
      color: white;
      font-weight: bold;
    }

    p {
      color: white;
      font-size: 14px;
      margin-bottom: 0px;
    }

    &:hover {
      background-color: white;

      label {
        color: black;
      }

      p {
        color: black;
      }
    }
  }

  .related-to-img {
    background-color: #00b97a;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 2rem;
    position: relative;
    height: 600px;

    ul {
      width: 300px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: start;
      gap: 2rem;
      list-style: none;
      padding-left: 0px;

      li {
        width: 300px;
        overflow: hidden;
      }
    }
  }

  .list {
    margin-bottom: 15px;
    cursor: pointer;
  }

  .related-to-img ul {
    list-style: none;
    padding: 0;
  }

  .related-to-img li {
    position: absolute;
    opacity: 1;
  }

  .image-slide {
    transition: opacity 0.3s ease-in-out;
    width: 280px;
    margin-top: 15px;
  }
}

.our-app-wrap {
  background-color: #67df83;
  border-radius: 40px;
  min-height: 450px;
  margin-block: 60px;

  .c-height {
    min-height: 450px;
  }

  .left-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding-right: 2rem;
    gap: 1rem;

    h4 {
      font-size: 56px;
      line-height: 1.2;
      font-family: "Bitter", serif;
      font-weight: 800;
      margin-bottom: 0px;
      color: black;
    }

    p {
      font-size: 20px;
      line-height: 1.5;
      color: #08080899;
      font-weight: 500;
    }
  }
}

.what-our-user-wrap {
  padding: 5rem 0px;
  background-color: #000000;

  .header-line {
    text-align: center;

    h3 {
      font-size: 56px;
      line-height: 1.2;
      font-family: "Bitter", serif;
      font-weight: 800;
      margin-bottom: 10px;
      color: white;
    }

    p {
      font-size: 20px;
      line-height: 1.5;
      color: #7f7f7f;
    }
  }
}

.scroll-horizontal-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  /* Enables horizontal scrolling */
  scroll-behavior: smooth;
  /* Optional: adds smooth scrolling */
  padding: 10px;
  gap: 1rem;
}

.user-bx {
  background-color: white;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  height: 393px;
  align-items: center;
  padding: 30px;
  gap: 21px;
  margin: 0px auto;

  img {
    max-width: 110px;
  }

  p {
    font-size: 12px;
    margin-bottom: 0px;
  }

  .dot {
    width: 9px;
    height: 15px;
    background: #67df83;
    border-radius: 9px;
    display: flex;
    border: 6px solid #67df83;
  }

  .name {
    text-align: center;
    display: flex;
    flex-direction: column;

    strong {
      font-size: 15px;
      font-weight: 700;
    }

    label {
      font-size: 14px;
      margin-bottom: 0px;
      color: #08080899;
    }
  }
}

.contact-us-wrap {
  background-color: white;
  padding: 60px 0px 0px;

  .header-line {
    text-align: center;

    h3 {
      font-size: 56px;
      line-height: 1.2;
      font-family: "Bitter", serif;
      font-weight: 800;
      margin-bottom: 10px;
      color: #080808;
    }

    p {
      font-size: 20px;
      line-height: 1.5;
      color: #7f7f7f;
    }
  }

  .contanct-section {
    background-color: #000000;
    padding: 40px;
    min-height: 670px;
    display: flex;
    border-radius: 40px 40px 40px 40px;
    gap: 72px;
  }

  .contact-info {
    min-height: 100%;
    background: url(assets/drop-bg.svg) white no-repeat bottom right;
    border-radius: 34px 34px 0px 34px;
    display: flex;
    flex-direction: column;
    width: 490px;
    padding: 2rem;
    justify-content: space-between;
    min-height: 647px;

    .heading {
      h5 {
        font-weight: 600;
        font-size: 28px;
        color: #080808;
      }

      label {
        font-size: 16px;
        color: #08080899;
        font-weight: 500;
      }
    }

    .social-media {
      // margin-top: auto;
      display: flex;
      gap: 1rem;
    }

    .cont-detl {
      display: flex;
      gap: 50px;
      flex-direction: column;
      padding-bottom: 2rem;

      >div {
        display: flex;
        gap: 1rem;
        color: #08080899;
        font-size: 16px;
        font-weight: 600;

        label {
          color: #08080899;
          font-size: 16px;
          font-weight: 600;
        }
      }

      span {
        width: 25px;
        display: flex;
      }
    }
  }

  .cont-text-area {
    width: calc(100% - 490px);
    display: flex;
    align-items: center;

    .custom-field {
      flex-direction: column;
      display: flex;

      label {
        font-size: 14px;
        color: white;
      }

      input {
        border: none;
        border-bottom: 1px solid #f0f0f0;
        padding: 10px;
        background-color: transparent;
        color: white;

        &:focus {
          outline: none;
        }
      }

      textarea {
        border: none;
        border-bottom: 1px solid #f0f0f0;
        padding: 10px;
        background-color: transparent;
        color: white;
        resize: none;

        &:focus {
          outline: none;
        }
      }

      .form-check-input[type="checkbox"] {
        width: 1em;
        height: 1em;
        border-radius: 50%;
        background-color: white;
        border: 0px solid #0d6efd;
        transition: background-color 0.3s ease, border-color 0.3s ease;
      }

      .form-check-input[type="checkbox"]:checked {
        background-color: #0d6efd;
        border-color: #0d6efd;
      }

      .form-check {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }

    .send-btn {
      background-color: #67df83;
      color: black;
      font-size: 20px;
      border-radius: 40px;
      padding: 10px;
      min-width: 210px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      font-weight: 600;
      min-height: 64px;
    }
  }
}

.faq-wrap {
  background-color: #080808;
  padding: 60px 0px 80px;
  margin-block: 60px;
  margin-bottom: 0px;

  .header-line {
    text-align: center;

    h3 {
      font-size: 56px;
      line-height: 1.2;
      font-family: "Bitter", serif;
      font-weight: 800;
      margin-bottom: 10px;
      color: white;
    }

    p {
      font-size: 20px;
      line-height: 1.5;
      color: #7f7f7f;
    }
  }

  .accordion-button::after {
    font-family: "FontAwesome";
    content: "\002B";
    font-weight: bold;
    margin-left: auto;
    font-size: 1.2rem;
    background: #00b97a;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }

  .accordion-button.collapsed::after {
    content: "\002B";
    /* Plus icon */
  }

  .accordion-button:not(.collapsed)::after {
    content: "\2212";
    /* Minus icon */
  }

  .faq-contnt {
    .accordion-item {
      color: white;
      font-size: 1.2rem;
    }

    .accordion-button {
      font-size: 1.2rem;
      padding: 1.2rem 0px;
    }

    .accordion-button[aria-expanded="false"] {
      border-bottom: 1px solid white;
    }

    .accordion-button[aria-expanded="true"] {
      border-bottom: 1px solid transparent;
    }

    .accordion-body {
      border: 1px solid #5c5c5c;
      padding: 1rem;
      font-size: 1rem;
      color: rgba(255, 255, 255, 0.607);
      border-radius: 10px;
    }
  }
}

.footer-content {
  background-color: #67df83;
  padding: 80px 0px 0px;

  .ft-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .app-store {
      display: flex;
      gap: 1rem;
    }
  }

  .ft-link-content {
    margin-top: 3rem;
    margin-bottom: 2rem;

    label {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 1rem;
      color: #080808;
    }

    p {
      color: hsla(0, 0%, 3%, 0.539);
      line-height: 1.5rem;
      margin-bottom: 0px;
    }

    ul {
      padding-left: 0px;
      list-style: none;
      gap: 10px;
      display: flex;
      flex-direction: column;

      li {
        a {
          color: #343434;
          text-decoration: none;
        }
      }
    }

    .about-content {
      width: 80%;

      label {
        font-size: 24px;
      }
    }
  }

  .ft-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #080808;
    padding-top: 2rem;
    padding-bottom: 2rem;

    .social-follow {
      display: flex;
      gap: 1.5rem;

      a {
        svg {}
      }
    }
  }
}

.reward-wrap {
  ::ng-deep .owl-carousel .owl-item img {
    width: 96%;
  }
}

.Marquee {
  width: 100vw;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1em;
  color: #fff;
  font-weight: 200;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  overflow: hidden;
}

.Marquee-content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-animation: marquee 50s linear infinite running;
  -moz-animation: marquee 50s linear infinite running;
  -o-animation: marquee 50s linear infinite running;
  -ms-animation: marquee 50s linear infinite running;
  animation: marquee 50s linear infinite running;
}

.Marquee-content:hover {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  -ms-animation-play-state: paused;
  animation-play-state: paused;
}

.Marquee-tag {
  width: 200px;
  margin: 0 0.5em;
  padding: 0.5em;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;

  img {
    max-width: 100%;
  }
}

.Marquee-tag:hover {
  background: rgba(255, 255, 255, 0.5);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  cursor: pointer;
}

@-moz-keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -o-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}

@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -o-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}

@-o-keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -o-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}

@keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -o-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}

.marquee-slide {
  -webkit-animation: marquee 10s linear infinite running;
  -moz-animation: marquee 10s linear infinite running;
  -o-animation: marquee 10s linear infinite running;
  -ms-animation: marquee 20s linear infinite running;
  animation: marquee 10s linear infinite running;
}

.why-business-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ededf1;
  padding: 80px 0;

  .header-line {
    text-align: center;

    h3 {
      font-size: 56px;
      line-height: 1.2;
      font-family: "Bitter", serif;
      font-weight: 800;
      margin-bottom: 10px;
      color: #080808;
    }

    p {
      font-size: 20px;
      line-height: 1.5;
      color: #7f7f7f;
    }
  }

  .inner-header-line {
    h3 {
      font-size: 2rem;
      line-height: 1.2;
      font-weight: 800;
      margin-bottom: 10px;
      color: white;
    }

    p {
      font-size: 16px;
      line-height: 1.5;
      color: rgb(255 255 255 / 70%);
    }
  }

  .b-related {
    background-color: #004610;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 2rem 0px;
  }

  .h--box {
    background-color: #191919;
    border-radius: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: calc(100% - 15px);
    padding: 2rem 0rem 0px 2rem;
  }

  .h--box1 {
    background-color: #3609c5;
    border-radius: 24px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: calc(100% - 17px);
    padding: 2rem 0rem 0px 2rem;
    margin-top: auto;
  }
}

/* Condition base stylesheet */

.business-content {
  h3 {
    color: #00b97a;
  }

  .header-line {
    h3 {
      color: #00b97a !important;
    }
  }
}

/* Product detail */

.product-detail-wrap {
  background-color: black;
  padding: 80px 0px;

  .item-detail {
    .i-name {
      span {
        color: white;
        display: flex;
        align-items: center;

        strong {
          color: #00b97a;
          font-weight: 500;
        }
      }

      sub {
        font-size: 1rem;
        color: rgba(255, 255, 255, 0.6);
      }
    }

    h3 {
      font-size: 3rem;
      color: white;
      font-weight: 600;
    }
  }

  .time-slot {
    display: flex;
    gap: 10px;
    color: white;
    font-weight: 600;
    align-items: center;

    .date {
      background-color: #141414;
      border-radius: 12px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .date-show {
      p {
        margin-bottom: 0px;
      }
    }
  }

  .request-join-btn {
    background-color: #00b97a;
    color: black;
    min-width: 320px;
    border-radius: 12px;
    padding: 1rem 2rem;
    font-size: 20px;
    border: none;
    font-weight: 600;
  }

  .related-content {
    label {
      font-size: 2rem;
      color: white;
    }

    p {
      color: rgba(255, 255, 255, 0.6);
      font-size: 1rem;
    }
  }

  .hosted-by {
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 1rem;
    border-radius: 16px;
    width: 100%;

    img {
      width: 70px;
      height: 70px;
    }
  }

  .follow-user {
    position: relative;
    display: flex;
    height: 30px;

    img {
      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 24px;
      right: 0px;
    }

    .img-1 {
      right: 0px;
    }

    .img-2 {
      right: 15px;
    }

    .img-3 {
      right: 30px;
    }
  }
}

@media (max-width: 1199px) {
  .banner-img {
    .frame-section {
      img {
        max-width: 100%;
      }
    }
  }

  .banner-text-wrap {
    h3 {
      font-size: 3rem;
    }
  }

  .how-it-works-wrapper .header-line h3,
  .reward-wrap .header-line h3,
  .why-business-wrap .header-line h3,
  .why-choose-wrap .header-line h3,
  .our-app-wrap .left-content h4,
  .what-our-user-wrap .header-line h3,
  .contact-us-wrap .header-line h3,
  .why-customer-us .header-line h3,
  .why-customer-us .box h3 {
    font-size: 2.5rem;
  }

  .first-section {
    h3 {
      font-size: 2rem;
    }

    p {
      font-size: 18px;
    }
  }

  .our-app-wrap {
    .left-content {
      img {
        max-width: 100%;
      }
    }
  }

  .contact-us-wrap {
    .contanct-section {
      gap: 2rem;
    }

    .contact-info {
      width: 380px;
    }

    .cont-text-area {
      width: calc(100% - 380px);
    }
  }

  .footer-content {
    .ft-link-content {
      label {
        font-size: 1.2rem;
      }
    }
  }

  .product-detail-wrap {
    .item-detail {
      h3 {
        font-size: 2rem;
      }
    }
  }

  .grid-container {
    .box {
      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 990px) {
  .banner-wrapper {
    padding-bottom: 2rem;
    min-width: 100px;
  }

  .banner-text-wrap {
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;

    >div {
      width: 100%;
    }

    h3 {
      font-size: 2rem;
    }

    .text-tap {
      order: 2;
      margin-top: 1.5rem;
      text-align: center;
    }

    .banner-img {
      justify-content: center;
      order: 1;

      .frame-section {
        text-align: center;
      }
    }
  }

  .contanct-section {
    min-height: auto;
  }

  .contact-us-wrap .contanct-section {
    flex-direction: column;
  }

  .how-it-works-wrapper .header-line h3,
  .reward-wrap .header-line h3,
  .why-business-wrap .header-line h3,
  .why-choose-wrap .header-line h3,
  .our-app-wrap .left-content h4,
  .what-our-user-wrap .header-line h3,
  .contact-us-wrap .header-line h3,
  .how-it-works-wrapper .header-line h3,
  .why-customer-us .header-line h3,
  .why-customer-us .box h3 {
    font-size: 2rem;
  }

  .first-section {
    flex-direction: column;
    padding-block: 2rem;
    gap: 1.5rem;
  }

  .banner-wrapper .choose-category {
    font-size: 1rem;
    min-width: 100px;
  }

  .header-nav {
    .logo img {
      width: 100px;
    }
  }

  .how-it-works-wrapper {
    padding: 32px 0px;

    .header-line {
      margin-bottom: 32px !important;
    }
  }

  .why-business-wrap {
    padding: 32px 0px;

    .header-line {
      margin-bottom: 32px !important;
    }
  }

  .b-related {
    margin-bottom: 20px;
  }

  .why-business-wrap .h--box1,
  .why-business-wrap .h--box {
    overflow: hidden;
  }

  .why-choose-wrap {
    padding: 32px 0px;

    .header-line {
      margin-bottom: 15px !important;
    }
  }

  .our-app-wrap {
    padding: 2rem;
    padding-bottom: 0px;
    background-color: transparent;
    margin-block: 2rem;
    padding: 0px;

    .c-height {
      min-height: auto;
      order: 1;
    }

    .left-content {
      order: 2;
      background-color: #67df83;
      padding: 2rem;
      border-radius: 2rem;
    }
  }

  .what-our-user-wrap {
    padding: 2rem 0;

    .header-line {
      margin-bottom: 2rem !important;
    }
  }

  .contact-us-wrap {
    padding: 2rem 0px;

    .contanct-section {
      flex-direction: column;
    }

    .contact-info {
      border-radius: 40px;
      width: 100%;
      min-height: auto;
      gap: 2rem;

      .cont-detl {
        gap: 1rem;
      }
    }

    .header-line {
      margin-bottom: 2rem !important;
    }

    .cont-text-area {
      width: 100%;
      min-height: auto;
      gap: 2rem;
    }
  }

  .faq-wrap {
    padding: 2rem 0px;
    margin-top: 2rem;
  }

  .footer-content {
    padding: 2rem 0px 0px;
  }

  .app-store {
    img {
      width: 150px;
    }
  }

  .ft-logo {
    img {
      width: 120px;
    }
  }

  .footer-content {
    .ft-link-content {
      margin-top: 2rem;

      .about-content {
        margin-bottom: 1.5rem;
        width: 100%;
      }
    }
  }

  .our-app-wrap {
    .c-height {
      min-height: auto;
    }
  }

  .cont-text-area {
    .row {
      .col-sm-12 {
        margin-bottom: 1rem;
      }
    }

    &>div {
      display: flex;
      flex-direction: column;
    }
  }

  .product-detail-wrap {
    .top-header {
      img {
        width: 100px;
      }
    }
  }

  .product-detail-wrap {
    .request-join-btn {
      font-weight: 1.2rem;
      min-width: auto;
    }
  }

  .item-detail {
    margin-top: 1rem;
  }

  .related-content {
    margin-top: 1.5rem !important;
  }
}

@media (max-width: 575px) {
  .banner-wrapper {
    min-height: auto;
  }

  .footer-content {
    .ft-top {
      flex-direction: column;
      gap: 1rem;
    }

    .ft-bottom {
      flex-direction: column;
      gap: 1rem;
      padding-block: 1rem;
    }
  }

  .faq-wrap {
    .faq-contnt {
      .accordion-button {
        font-size: 1rem;
      }
    }
  }

  .how-it-works-wrapper .header-line h3,
  .reward-wrap .header-line h3,
  .why-business-wrap .header-line h3,
  .why-choose-wrap .header-line h3,
  .our-app-wrap .left-content h4,
  .what-our-user-wrap .header-line h3,
  .contact-us-wrap .header-line h3,
  .how-it-works-wrapper .header-line h3,
  .banner-text-wrap h3,
  .why-customer-us .header-line h3,
  .why-customer-us .box h3,
  .product-detail-wrap .related-content label,
  .product-detail-wrap .item-detail h3 {
    font-size: 1.5rem;
  }

  .contact-us-wrap {
    .contanct-section {
      padding: 1rem 1rem;
    }

    .contact-info {
      .heading {
        h5 {
          font-size: 1.5rem;
        }
      }
    }
  }

  .our-app-wrap {
    .left-content {
      padding: 1rem;

      p {
        font-size: 1.2rem;
      }
    }
  }

  .why-choose-wrap {
    .list {
      margin-bottom: 0px;
      padding: 1rem;

      label {
        font-size: 1.2rem;
      }
    }
  }

  .why-business-wrap .h--box1,
  .why-business-wrap .h--box {
    padding: 1rem;
    height: auto;
    margin-bottom: 1rem;
  }

  .why-business-wrap {
    padding-bottom: 1rem;

    .inner-header-line {
      h3 {
        font-size: 1.5rem;
      }
    }
  }

  .reward-wrap {
    padding: 32px 0rem;

    .img-wrap {
      flex-direction: column;

      .box {
        width: 100%;
        height: 280px;
        overflow: hidden;
        display: flex;

        img {
          width: 100%;
          border-radius: 40px;
          overflow: hidden;
          object-fit: cover;
        }
      }
    }

    .header-line {
      margin-bottom: 1.5rem !important;
    }
  }

  .how-it-works-wrapper .header-line p,
  .reward-wrap .header-line p,
  .why-business-wrap .header-line p,
  .why-choose-wrap .header-line p,
  .our-app-wrap .left-content p,
  .what-our-user-wrap .header-line p,
  .contact-us-wrap .header-line p,
  .how-it-works-wrapper .header-line p,
  .banner-text-wrap p {
    font-size: 1rem;
  }

  .first-section {
    h3 {
      font-size: 1.5rem;
      font-size: 1.5rem;
    }
  }

  .Marquee {
    padding: 0px;
  }

  .Marquee-tag {
    width: 120px;
  }

  .why-customer-us {
    .grid-container {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }
  }
}